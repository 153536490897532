<script>
import { mapGetters } from "vuex";
// ! Agregar versiones para los cargos
export default {
  name: "PerfilCargo",
  async mounted() {
    let { id, empresa } = JSON.parse(localStorage.setPersonaAct);
    let persona = JSON.parse(localStorage.setPersonaAct);
    let ubicacionPersonaData = await this.$store.dispatch("hl_get", {
      path: "PersonaUbicacion/GetByPersona/" + persona.id,
    });
    this.sucursal = ubicacionPersonaData.sucursal;

    this.empresa = empresa;
    const personaId = id;

    const reponseUbicacion = await this.$store.getters.fetchGet({ path: `PersonaUbicacion/GetByPersonaId/${personaId}` });
    let responseUbicacion = await reponseUbicacion.json();
    const cargo = responseUbicacion.cargo;
    this.reponseUbicacion = responseUbicacion.sucursal;
    // console.log(cargo);
    await this.getFuncionesResponsabilidadYCompetencias(cargo.id);
    await this.getNivelCompetenciaComplejidad();

    const responseCargoEditado = await this.$store.getters.fetchGet({ path: `Cargo/todoSobreElCargo/${cargo.id}` });
    const cargoIntroduction = await responseCargoEditado.json();
    this.cargo = cargoIntroduction.cargo;
    this.competencias = cargoIntroduction.competencias;
    // console.log('competencias', cargoIntroduction.competencias);

    const responsePerfilCargo = await this.$store.getters.fetchGet({ path: `perfilcargo/consultarPerfilCargoAll/${cargo.id}` });
    const perfilCargo = await responsePerfilCargo.json();
    this.perfilCargo = perfilCargo;

    // this.nivelCompetenciaComplejidadText = this.ListNivelCompetenciaComplejidad.filter((x) => x.id == perfilCargo.perfilCargo.nivelCompetenciaComplejidadId)[0].descripcion;

    let nivel = this.ListNivelCompetenciaComplejidad.filter((x) => x.id == perfilCargo.perfilCargo.nivelCompetenciaComplejidadId);

    if (nivel.length > 0) {
      this.nivelCompetenciaComplejidadText = "Nivel " + nivel[0].id + ": " + nivel[0].descripcion;
    }

    this.$forceUpdate();

    // console.log('perfilCargo', perfilCargo);
    this.$store.getters
      .fetchGet({ path: `Cargo/ListCargo/${empresa.id}` })
      .then((response) => response.json())
      .then((result) => {
        this.todosLosCargos = result;
        // console.log('todosLosCargos', result);
      });

    this.$store.getters
      .fetchGet({ path: `CargosAceptados/ListaPorPersonaYPerfil/${id}/${this.perfilCargo.perfilCargo.id}` })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        if (response.status == 404) {
          this.perfilCargoPersonaAceptado = false;
          throw new Error("");
        }
      })
      .then((result) => {
        this.perfilCargoPersonaAceptado = result;
      })
      .catch((error) => {
        console.error("Controlado", error);
      });

    //abr 02062024
    if (this.reponseUbicacion.descripcion == "PAYNET" || this.reponseUbicacion.descripcion == "GSE") {
      this.$store.getters
        .fetchGet({ path: `CargoCompetencia/GetFuncionesGeneralesGSE` })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("No se han encontrado funciones generales");
        })
        .then((result) => {
          if (this.reponseUbicacion.descripcion == "PAYNET") {
            result = result.filter((x) => x.gseSucursal.includes("P"));
            this.funcionesGenerales = result;
          } else {
            result = result.filter((x) => x.gseSucursal.includes("G"));
            this.funcionesGenerales = result;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.$store.getters
        .fetchGet({ path: `CargoCompetencia/GetFuncionesGeneralesByCargoId/${this.cargo.id}` })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("No se han encontrado funciones generales");
        })
        .then((result) => {
          this.funcionesGenerales = result;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
  data() {
    return {
      reponseUbicacion: {},
      empresa: {},
      perfilCargoPersonaAceptado: null,

      cargo: {},
      perfilCargo: {},
      competencias: [],
      todosLosCargos: [],
      loader: false,
      funcionesGenerales: [],

      funcionesDeResponsabilidad: [],
      nivelCompetenciaComplejidadText: null,
      ListNivelCompetenciaComplejidad: [],
      sucursal: {},
    };
  },
  methods: {
    monthToYear(month) {
      if (month < 12) {
        return `${month} meses`;
      } else {
        let years = Math.floor(month / 12);
        let months = month % 12;
        return `${years} años ${months} meses`;
      }
    },
    getNivelCompetenciaComplejidad() {
      this.$store.getters
        .fetchGet({ path: `NivelCompetenciaComplejidad/ListNivelCompetenciaComplejidad/` })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Error al obtener las competencias del cargo");
        })
        .then((result) => {
          this.ListNivelCompetenciaComplejidad = result;
        })
        .catch((error) => console.log(error));
    },
    nombreDelCargoById(cargoId = "") {
      if (this.todosLosCargos.length == 0) return;
      return this.todosLosCargos.find((c) => c.id == cargoId);
    },
    async getFuncionesResponsabilidadYCompetencias(cargoId) {
      // ? Quizás este método pueda mejorar desde el backend

      const resp = await this.$store.getters.fetchGet({ path: `PerfilCargo/FuncionesDeResponsabilidadByCargo/${cargoId}` });
      this.funcionesDeResponsabilidad = await resp.json();

      const respp = await this.$store.getters.fetchGet({ path: `PerfilCargo/FuncionesDeResponsabilidadByCargoYCompetencias/${cargoId}` });
      const funcionesYCompetencias = await respp.json();

      this.funcionesDeResponsabilidad.forEach((funcion) => {
        funcion.responsabilidades = funcionesYCompetencias.filter((f) => f.funcionId == funcion.id);
      });
    },
    aceptarCargo() {
      // console.log(this.$store.getters.userLoggedIn);
      // console.log(this.perfilCargo);
      // console.log(this.cargo);

      let persona = this.$store.getters.userLoggedIn;
      const data = {
        personaId: persona.id,
        PerfilId: this.perfilCargo.perfilCargo.id,
        Estado: 1,
        CargoId: this.cargo.id,
        nombres: persona.nombres,
        apellidos: persona.apellidos,
        correo: persona.correo,
        numeroDocumento: persona.numeroDocumento,
        tipoDocumentoId: persona.tipoDocumento.id,
        sucursal: this.cargo.sucursal.descripcion,
      };

      this.loader = true;
      this.$store.getters
        .fetchPost({ path: "CargosAceptados", data })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("No se ha podido guardar el cargo");
        })
        .then((result) => {
          this.perfilCargoPersonaAceptado = result;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
  computed: {
    ...mapGetters(["toFormatDate"]),
    competenciasDelCargo() {
      return this.competencias.filter((c) => c.competencia.tipoCompetencia.descripcion != "Responsabilidad");
    },
    funcionesYResponsabilidades() {
      const responsabilidades = this.competencias.filter((c) => c.competencia.tipoCompetencia.descripcion == "Responsabilidad");

      let funciones = {};

      responsabilidades.forEach((responsabilidad) => {
        if (responsabilidad.cargoFunciones != null) {
          if (Object.keys(funciones).includes(responsabilidad.cargoFunciones.nombre)) {
            funciones[responsabilidad.cargoFunciones.nombre].push(responsabilidad);
          } else {
            funciones[responsabilidad.cargoFunciones.nombre] = [responsabilidad];
          }
        } else {
          if (Object.keys(funciones).includes("Sin función")) {
            funciones["Sin función"].push(responsabilidad);
          } else {
            funciones["Sin función"] = [responsabilidad];
          }
        }
      });

      // console.log('responsabilidades', responsabilidades);

      return funciones;
    },
    funcionesTipoG() {
      if (this.item.funciones) {
        return this.item.funciones.filter((f) => f.tipo == "G");
      } else {
        return [];
      }
    },
    // funcionesTipoNotG() {
    //   if (this.item.funciones) {
    //     return this.item.funciones.filter(f => f.tipo != 'G')
    //   }
    //   else {
    //     return []
    //   }
    // }
  },
  filters: {
    getNombre(cargo) {
      if (cargo) {
        return cargo.nombre;
      } else {
        return "";
      }
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <b-row class="justify-content-lg-center">
      <div class="col-xl-3">
        <card style="height: 80%">
          <div class="pt-3 pb-5">
            <div class="px-5 mt-3 text-center" style="position: relative; align-items: center !important">
              <img
                :src="sucursal.urlLogo != null ? sucursal.urlLogo : empresa.urlLogo"
                class="img-fluid circular--landscape img"
                style="border-radius: 10px; border: solid 1px red"
              />
            </div>
          </div>
        </card>
      </div>
      <div class="col-xl-9">
        <card style="height: 80%">
          <div class="row px-4">
            <div class="col-md-6 text-center">
              <div style="padding: 3% 0">
                <div style="padding: 10% 0">
                  <span class="h2 text-white"><b>PERFIL Y FUNCIONES DE CARGO</b><br /></span>
                  <span class="h3 text-light"><b>Talento Humano</b></span>
                </div>
              </div>
            </div>

            <div class="col-md-6 pt-4">
              <div class="row">
                <ul v-if="perfilCargo.perfilCargo">
                  <li class="my-2"><b>Código:</b> {{ perfilCargo.perfilCargo.codigo }}</li>
                  <li class="my-2"><b>Versión:</b> {{ perfilCargo.perfilCargo.version }}</li>
                  <li class="my-2"><b>Implementación:</b> {{ $moment.tz(perfilCargo.perfilCargo.implementacion, "America/Bogota").format("ll") }}</li>
                  <li class="my-2"><b>Clasificación:</b> {{ perfilCargo.perfilCargo.clasificacion }}</li>
                  <li class="my-2"><b>Fecha de actualización:</b> {{ $moment.tz(perfilCargo.perfilCargo.fechaActualizacion, "America/Bogota").format("ll") }}</li>
                </ul>
              </div>
            </div>
          </div>
        </card>
      </div>
    </b-row>
    <card class="py-4 px-4">
      <div>
        <div class="mb-4">
          <b-row class="my-2">
            <b-col sm>
              <h5 class="mb-3">Información del cargo</h5>
              <div v-if="Object.keys(cargo).length">
                <p><b>Nombre del cargo:</b> {{ nombreDelCargoById(cargo.id) | getNombre }}</p>
                <!-- <p>
                  <b>Proceso:</b> {{ item.proceso }}
                </p> -->
                <p><b>Responsable inmediato:</b> {{ nombreDelCargoById(cargo.cargoDependienciaId) | getNombre }}</p>
                <p><b>Nivel:</b> {{ cargo.tipoCargo.descripcion }}</p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm>
              <h5>Formación</h5>
              <b-list-group>
                <b-list-group-item v-for="(etapa, index) in perfilCargo.formacion" :key="'formacion' + index">
                  <p><b>Titulo:</b> {{ etapa.descripcion }}</p>
                  <!-- <p><b>Indispensable:</b> {{ etapa.indispensable ? "Si" : "No" }}</p>
                  <p><b>Deseable:</b> {{ etapa.deseable ? "Si" : "No" }}</p> -->
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col sm>
              <h5>Experiencia</h5>
              <b-list-group>
                <b-list-group-item v-for="(etapa, index) in perfilCargo.experiencia" :key="'exp' + index">
                  <p><b>Area y/o especialidad:</b> {{ etapa.especialidad }}</p>
                  <p><b>Homologación:</b> {{ etapa.homologacion }}</p>
                  <p><b>Tiempo:</b> {{ monthToYear(etapa.mesesExperiencia) }}</p>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col sm>
              <h5>Educación</h5>
              <b-list-group>
                <b-list-group-item v-for="(etapa, index) in perfilCargo.educacion" :key="'educacion' + index">
                  <p><b>Homologación:</b> {{ etapa.titulo }}</p>
                  <p><b>Area y/o especialidad:</b> {{ etapa.especialidad }}</p>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </div>

        <div class="mb-4">
          <h5>{{ empresa.nombre == "Grupo GSE" ? "Habilidades" : "Competencias" }}</h5>
          <b-list-group>
            <b-list-group-item v-for="competencia in competenciasDelCargo" :key="competencia.titulo">
              <div class="display-grid">
                <div class="display-grid-body">
                  <p><b>Titulo:</b> {{ competencia.competencia.titulo }}</p>
                  <p><b>Descripción:</b> {{ competencia.competencia.descripcion }}</p>
                </div>
                <div class="display-grid-side">
                  <!-- {{ competencia }} -->
                  <!-- <p><b>Nivel:</b> {{ competencia.cargoCompetenciaNivel.value }}</p> -->
                  <p>
                    <!-- <b>Nivel:</b> <span v-if="competencia.hasOwnProperty('cargoCompetenciaNivel.value')">{{ competencia.cargoCompetenciaNivel.value }}</span> -->
                    <b>Nivel:</b> <span v-if="competencia.cargoCompetenciaNivel != null">{{ competencia.cargoCompetenciaNivel.value }}</span>
                  </p>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div class="mb-4">
          <h5>Objetivo del cargo</h5>
          <b-list-group v-if="Object.keys(perfilCargo).length">
            <b-list-group-item>
              <p><b>Objetivo:</b> {{ perfilCargo.perfilCargo.objetivo }}</p>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div class="mb-4">
          <h5>Nivel de {{ empresa.nombre == "Grupo GSE" ? "habilidad" : "competencia" }} y complejidad</h5>
          <p class="ml-4 mt-3 mb-3">{{ nivelCompetenciaComplejidadText }}</p>
        </div>

        <!-- ! AQuiiii abr:18062024 he agregado hasownproperty porque se generaba error-->
        <div class="mb-4">
          <h5>Funciones y responsabilidades del cargo</h5>
          <!-- {{ funcionesDeResponsabilidad }} -->
          <b-list-group>
            <b-list-group-item v-for="(funcion, idx) in funcionesDeResponsabilidad" :key="'funcion' + idx">
              <p class="mb-1" style="font-size: 1.05rem">
                {{ funcion.nombre }}
              </p>
              <ul style="padding-left: 0.6rem">
                <!-- <li class="text-justify" v-if="funcion.responsabilidades.length == 0">No hay responsabilidades</li> -->
                <li class="text-justify" v-if="!funcion.hasOwnProperty('responsabilidades') || funcion.responsabilidades.length == 0">No hay responsabilidades</li>
                <li class="text-justify" v-for="(responsabilidad, index) in funcion.responsabilidades" :key="`responsabilidad ${index}`">
                  {{ responsabilidad.competenciaTitulo }}
                </li>
              </ul>
            </b-list-group-item>
          </b-list-group>
        </div>

        <div class="mb-4">
          <h5>Funciones generales frente a los sistemas de gestión</h5>
          <p>(Gestión de Calidad, Gestión de Seguridad de la Información y Gestión de Seguridad y Salud en el Trabajo)</p>
          <div>
            <!-- TODO: Relacionar funciones en la creacion en Cargo_perfil -->
          </div>
          <b-list-group>
            <b-list-group-item v-for="funcion in funcionesGenerales" :key="'funcionG' + funcion.id">
              <p>
                {{ funcion.nombre }}
              </p>
            </b-list-group-item>
          </b-list-group>
        </div>

        <div class="mb-4">
          <h5>Observaciones</h5>
          <div v-if="Object.keys(perfilCargo).length">
            <b-list-group>
              <b-list-group-item>
                <p v-if="perfilCargo.perfilCargo.observaciones">
                  {{ perfilCargo.perfilCargo.observaciones }}
                </p>
                <p v-else>No hay observaciones</p>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>

        <div class="my-1 py-2">
          <div v-if="perfilCargoPersonaAceptado == null" class="my-2">
            <i class="fas fa-spinner fa-spin text-success"></i>
          </div>
          <div v-else-if="perfilCargoPersonaAceptado == false" class="my-2">
            <button class="btn btn-outline-success px-5 my-1" @click="aceptarCargo">
              <span v-if="loader">
                <i class="fas fa-spinner fa-spin"></i>
              </span>
              <span v-else> Confirmar y Aceptar información </span>
            </button>
          </div>
          <div v-else class="my-2">
            <b-list-group>
              <b-list-group-item>
                <p class="h5 text-success">El perfil ya fue aceptado.</p>
                Fecha de aceptación:
                {{ $moment.tz(perfilCargoPersonaAceptado.FechaDeCreacion, "America/Bogota").format("ll") }}
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<style scoped>
.circular--landscape {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  /* border-radius: 50%; */
  object-fit: contain;
  /* background-color: #fff */
}
.circular--landscape img {
  width: auto;
  height: 100%;
  margin-left: -50px;
  object-fit: contain;
}
.display-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1rem;
}

.display-grid-body {
  grid-column: 1 / span 10;
}

.display-grid-side {
  grid-column: 11 / span 2;
}

@media (max-width: 768px) {
  .display-grid {
    display: block;
  }

  .display-grid-body {
    grid-column: 1 / span 12;
  }

  .display-grid-side {
    grid-column: 1 / span 12;
  }
}
</style>
